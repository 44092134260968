<!--
 * @Author: steven 1105624290@qq.com
 * @Date: 2022-07-19 10:03:33
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2022-07-19 10:39:10
 * @FilePath: /ytjj-mobile/src/views/pd/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="pd_view">
    <router-view />
  </div>
</template>
<script>

export default {
  
};
</script>
<style>
    .pd_view{
        width: 100%;
        height: 100%;
    }
</style>